import { Float } from '@headlessui-float/react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import React, { useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import ArrowDown from 'shared/icons/arrow-down-icon'
import CheckIcon from 'shared/icons/check-icon'
import CloseIcon from 'shared/icons/close-icon'
import { Loader } from './loader'

export interface OptionsInterface {
  id: string | number
  caption: string
}

export interface DataInterface<T extends OptionsInterface> {
  id: T['id']
  caption: string
}

export interface FormSelectProps<T extends OptionsInterface> {
  label?: string
  data?: T[]
  onChange: (data: DataInterface<T>['id']) => void
  value?: DataInterface<T>['id']
  placeholder?: string
  error?: string
  required?: boolean
  className?: string
  withoutCloseIcon?: boolean
}

function FormSelect<T extends OptionsInterface>({
  label,
  data,
  onChange,
  value,
  placeholder = 'global.select',
  error,
  required,
  withoutCloseIcon = false,
  className,
}: FormSelectProps<T>) {
  const { t } = useTranslation()
  const caption = data?.find(el => el.id === value)?.caption
  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  return (
    <div className={twMerge(`relative flex flex-col gap-1`, className)}>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Float
              offset={10}
              portal
              onUpdate={() => {
                if (dropdownRef.current && buttonRef.current) {
                  const buttonWidth = buttonRef.current.getBoundingClientRect().width
                  const dropdownWidth = dropdownRef.current.getBoundingClientRect().width
                  if (dropdownWidth < buttonWidth) {
                    dropdownRef.current.style.width = `${buttonWidth}px`
                  }
                }
              }}
              flip
              placement="bottom-end"
              as="div"
              enter="transition duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex flex-col gap-1">
                {(label || required) && (
                  <Label className="flex gap-0.5 text-sm font-medium text-darkblue">
                    {label && <span>{label}</span>}
                    {required && <span className="text-red">*</span>}
                  </Label>
                )}
                <ListboxButton
                  className={`relative flex w-full cursor-default items-center justify-between rounded-lg border bg-white px-4 py-2.5 text-left text-sm text-darkblue transition-colors duration-200 ease-linear ${
                    open ? 'border-blue' : 'border-gray/50'
                  } ${open && 'border-blue'} focus:outline-none focus-visible:border-blue`}
                  as={'button'}
                  ref={buttonRef}
                >
                  <>
                    {value && data ? caption : t(placeholder)}
                    <div className="ml-5 flex items-center gap-1">
                      {value && !withoutCloseIcon && (
                        <CloseIcon
                          className={`cursor-pointer fill-darkblue`}
                          onClick={() => onChange(undefined!)}
                        />
                      )}
                      <ArrowDown
                        className={`stroke-darkblue transition-transform duration-300${
                          open && 'rotate-180'
                        }`}
                      />
                    </div>
                  </>
                </ListboxButton>
              </div>

              <ListboxOptions
                className={`shadow-around-sm max-h-60 w-full border-collapse overflow-auto rounded-md bg-white text-sm focus:outline-none`}
              >
                <div ref={dropdownRef}>
                  {data === undefined ? (
                    <div className="flex justify-center rounded-md border border-t-0 border-t-[1px] border-gray/50 px-4 py-1">
                      <Loader className="scale-75" />
                    </div>
                  ) : (
                    data.map(el => {
                      return (
                        <ListboxOption
                          key={el.id}
                          value={el.id}
                          className={({ focus }) =>
                            `group flex items-center justify-between border border-t-0 border-gray/50 px-4 py-2.5 first:rounded-t-md first:border-t-[1px] last:rounded-b-md hover:bg-blue hover:text-white ${
                              focus ? 'bg-blue text-white' : 'bg-white text-darkblue'
                            }`
                          }
                        >
                          {({ focus }) => (
                            <div className="flex w-full items-center justify-between gap-3">
                              {el.caption}
                              {el.id === value && (
                                <CheckIcon
                                  className={`h-4 w-4 group-hover:stroke-white ${
                                    focus ? 'stroke-white' : 'stroke-blue'
                                  }`}
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          )}
                        </ListboxOption>
                      )
                    })
                  )}
                </div>
              </ListboxOptions>
            </Float>
          </>
        )}
      </Listbox>
      {error && <p className="text-sm text-red">{error}</p>}
    </div>
  )
}

export default FormSelect
