import React, { useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import ProgressBar from 'shared/components/progress-bar'
import useMediaQuery from 'shared/hooks/use-media-query'
import { getFullOffsetOfElement } from 'shared/utils/get-full-offset-of-element'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { useCourseMenu } from 'modules/course/hooks/use-course-menu'
import useStepRouter from 'modules/course/hooks/use-step-router'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { PrimaryButtonWithCustomTheme } from '../primary-button-with-custom-theme'
import { SecondaryButtonWithCustomTheme } from '../secondary-button-with-custom-theme'
import { CourseModuleList } from './course-module-list'
import { CourseSideMenuSkeleton } from './course-side-menu-skeleton'

const MIN_MAX_MODULE_HEIGHT = 200
const BOTTOM_MODULE_OFFSET = 35

export interface CourseSideMenuProps {
  className?: string
  isLoading: boolean
}

export const CourseSideMenu = ({
  className,
  isLoading,
}: React.PropsWithChildren<CourseSideMenuProps>) => {
  const menuContainerRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const moduleListRef = useRef<HTMLUListElement>(null)

  const { isLG } = useMediaQuery()

  const { t } = useTranslation()

  const { onNextStep, onPreviousStep, isStart, isEnd } = useStepRouter()

  const { courseData } = useCourseData()
  const { courseMenu } = useCourseMenu({ courseId: courseData?.id })

  useLayoutEffect(() => {
    const html = document.querySelector('html')
    const resizeSideMenu = () => {
      if (menuRef.current && moduleListRef.current && menuContainerRef.current) {
        const menuFullOffset = getFullOffsetOfElement(menuRef.current)
        const moduleListFullOffset = getFullOffsetOfElement(moduleListRef.current)
        const htmlScrollTop = html?.scrollTop || 0

        const height = `${
          !isLG
            ? MIN_MAX_MODULE_HEIGHT
            : Math.max(
                document.body.clientHeight -
                  (moduleListFullOffset - menuFullOffset) -
                  Math.max(menuFullOffset - htmlScrollTop, 0) -
                  BOTTOM_MODULE_OFFSET,
                MIN_MAX_MODULE_HEIGHT,
              )
        }px`
        moduleListRef.current.style.maxHeight = height
        if (moduleListRef.current.clientHeight === MIN_MAX_MODULE_HEIGHT && isLG) {
          menuRef.current.style.overflowY = 'auto'
        } else {
          menuRef.current.style.overflowY = 'hidden'
        }
        if (menuContainerRef.current.clientHeight >= document.body.clientHeight - 40 && isLG) {
          menuRef.current.style.height = 'calc(100vh - 40px)'
        } else {
          menuRef.current.style.height = 'unset'
        }
      }
    }
    resizeSideMenu()
    window.addEventListener('scroll', resizeSideMenu)
    window.addEventListener('resize', resizeSideMenu)
    return () => {
      window.removeEventListener('scroll', resizeSideMenu)
      window.removeEventListener('resize', resizeSideMenu)
    }
  }, [courseMenu, isLG])

  return !isLoading ? (
    <div
      ref={menuRef}
      className={twMerge(
        `customScroll top-[20px] w-full rounded-md bg-THEME-block-background lg:sticky lg:w-[330px]`,
        className,
        addPublicCustomClassNamePrefix('course__side-menu'),
      )}
    >
      <div ref={menuContainerRef} className="p-6">
        <>
          <img
            className={twMerge(
              `mx-auto h-20 w-20 rounded-full object-cover`,
              addPublicCustomClassNamePrefix('course__instruction_photo'),
            )}
            src={
              courseData?.instructorPhoto?.path ||
              'https://secure.gravatar.com/avatar/93583d9d02765d3c1b628f1cd82961bd?s=80&r=g&d=mm'
            }
            alt={courseData?.instructorPhoto?.name}
          />
          <p
            className={twMerge(
              `mt-5 text-center text-2xl font-bold text-THEME-primary`,
              addPublicCustomClassNamePrefix('course__instruction_name'),
            )}
          >
            {courseData?.instructorName || ''}
          </p>
          <div className="flex gap-3 pt-7">
            <SecondaryButtonWithCustomTheme
              className={twMerge(
                `basis-6/12 font-bold`,
                addPublicCustomClassNamePrefix('course__button--previous-lecture'),
              )}
              disabled={isStart}
              onClick={onPreviousStep}
            >
              {t('course.previous_step_button_label')}
            </SecondaryButtonWithCustomTheme>
            <PrimaryButtonWithCustomTheme
              className={twMerge(
                `basis-6/12 font-bold`,
                addPublicCustomClassNamePrefix('course__button--next-lecture'),
              )}
              disabled={isEnd}
              onClick={onNextStep}
            >
              {t('course.next_step_button_label')}
            </PrimaryButtonWithCustomTheme>
          </div>
          <ProgressBar
            className={twMerge(
              `mt-8 !bg-THEME-block-background !text-THEME-primary`,
              addPublicCustomClassNamePrefix('course__progress-bar'),
            )}
            indicatorClassName="!bg-THEME-primary !text-THEME-block-background"
            progress={courseData?.progress || 0}
          />
          <CourseModuleList ref={moduleListRef} />
        </>
      </div>
    </div>
  ) : (
    <CourseSideMenuSkeleton />
  )
}
