import { useMemo } from 'react'
import { CourseStepEnum } from '../types/course-step-type'
import { useCourseData } from './use-course-data'
import { useCourseMenu } from './use-course-menu'
import { StepType } from './use-step-router/types'

export default function useCourseSteps() {
  const { courseData } = useCourseData()
  const { courseMenu } = useCourseMenu({ courseId: courseData?.id })

  const courseSteps = useMemo(() => {
    if (!courseMenu) return []

    const stepsArray: StepType[] = []

    for (let menuModule of courseMenu) {
      for (let lecture of menuModule.lectures) {
        stepsArray.push({
          module: {
            name: menuModule.name,
            available: menuModule.available,
            salesPage: menuModule.salesPage,
          },
          type: CourseStepEnum.lecture,
          ...lecture,
        })
      }
      if (menuModule.quiz) {
        stepsArray.push({
          module: {
            name: menuModule.name,
            available: menuModule.available,
            salesPage: menuModule.salesPage,
          },
          type: CourseStepEnum.quiz,
          ...menuModule.quiz,
        })
      }
    }

    return stepsArray
  }, [courseMenu])

  return { courseSteps }
}
