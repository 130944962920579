import { Float, FloatProps } from '@headlessui-float/react'
import React, { ReactNode, useRef } from 'react'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useIsOverflowText } from 'shared/hooks/use-is-overflow-text'
import useWindowSize from 'shared/hooks/use-window-size'

interface TooltipProps {
  children: ReactNode
  mode: 'click' | 'hover' | 'overflow'
  label: string | JSX.Element
  position?: FloatProps['placement']
  className?: string
  wrapperClassName?: string
  tooltipClassName?: string
}

export const Tooltip = ({
  children,
  mode,
  label,
  position,
  className,
  wrapperClassName,
  tooltipClassName,
}: TooltipProps) => {
  const targetRef = useRef(null)
  const { width } = useWindowSize()
  const { show, delayOpen, delayClose } = useFloatHover()

  const isOverflow = useIsOverflowText(targetRef)

  return (
    <Float
      portal
      show={show}
      placement={position || 'top'}
      offset={16}
      flip={{
        fallbackPlacements: ['top', 'bottom', 'left', 'right'],
        fallbackStrategy: 'initialPlacement',
      }}
      arrow
      enter="transition duration-100 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition duration-75 ease-in"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      ref={targetRef}
      className={wrapperClassName}
    >
      <div
        onMouseEnter={delayOpen}
        onMouseLeave={delayClose}
        className={`relative flex items-center justify-center truncate ${
          className ? className : 'h-full w-full'
        } ${mode === 'overflow' ? 'overflow-hidden' : ''}`}
      >
        {children}
      </div>
      <div
        className={`rounded-lg shadow-2xl ${show ? 'z-50' : 'z-0'} ${width < 1024 && 'invisible'} ${
          mode === 'overflow' ? (isOverflow ? 'visible' : 'invisible') : 'visible'
        } ${tooltipClassName || ''}`}
      >
        <Float.Arrow className="absolute h-5 w-5 rotate-45 border border-white bg-white" />
        <div className={`relative w-fit whitespace-normal rounded-lg bg-white px-5 py-3`}>
          <div className="w-full break-normal text-center font-avertaPE text-sm font-medium text-gray-300">
            {label}
          </div>
        </div>
      </div>
    </Float>
  )
}
