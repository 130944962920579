import { FC } from 'react'
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful'
import { ColorPickerBaseProps } from 'react-colorful/dist/types'
import { twMerge } from 'tailwind-merge'
import Popover from 'shared/components/popover'

const ColorPicker: FC<Partial<ColorPickerBaseProps<string>>> = ({ color, className, ...rest }) => {
  return (
    <Popover
      label={
        <div className={'flex flex-col gap-3'}>
          <HexAlphaColorPicker color={color} {...rest} />
          <div className={'flex'}>
            <div className={'rounded-l-lg bg-gray-200 p-2'}>#</div>
            <HexColorInput
              className={
                'main-transition-colors w-full rounded-r-lg border border-gray/30 p-2 outline-none focus:border-blue'
              }
              color={color}
              onChange={rest.onChange}
            />
          </div>
        </div>
      }
    >
      {() => (
        <div
          style={{ backgroundColor: color }}
          className={twMerge('h-10 w-10 rounded-full border border-gray/90', className)}
        />
      )}
    </Popover>
  )
}

export default ColorPicker
