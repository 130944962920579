export default function CollapseIcon() {
  return (
    <svg width="20" height="48" viewBox="0 0 20 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddd_1_10)">
        <path d="M0 6.4L20 0V48L0 41.6V6.4Z" fill="white" />
      </g>
      <g opacity="0.24">
        <path
          d="M6.25 18.75C7.49219 18.75 8.5 17.7422 8.5 16.5C8.5 15.2578 7.49219 14.25 6.25 14.25C5.00781 14.25 4 15.2578 4 16.5C4 17.7422 5.00781 18.75 6.25 18.75ZM6.25 26.25C7.49219 26.25 8.5 25.2422 8.5 24C8.5 22.7578 7.49219 21.75 6.25 21.75C5.00781 21.75 4 22.7578 4 24C4 25.2422 5.00781 26.25 6.25 26.25ZM8.5 31.5C8.5 30.2578 7.49219 29.25 6.25 29.25C5.00781 29.25 4 30.2578 4 31.5C4 32.7422 5.00781 33.75 6.25 33.75C7.49219 33.75 8.5 32.7422 8.5 31.5ZM13.75 18.75C14.9922 18.75 16 17.7422 16 16.5C16 15.2578 14.9922 14.25 13.75 14.25C12.5078 14.25 11.5 15.2578 11.5 16.5C11.5 17.7422 12.5078 18.75 13.75 18.75ZM16 24C16 22.7578 14.9922 21.75 13.75 21.75C12.5078 21.75 11.5 22.7578 11.5 24C11.5 25.2422 12.5078 26.25 13.75 26.25C14.9922 26.25 16 25.2422 16 24ZM13.75 33.75C14.9922 33.75 16 32.7422 16 31.5C16 30.2578 14.9922 29.25 13.75 29.25C12.5078 29.25 11.5 30.2578 11.5 31.5C11.5 32.7422 12.5078 33.75 13.75 33.75Z"
          fill="#142D63"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1_10"
          x="-48"
          y="-24"
          width="116"
          height="148"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="8"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1_10"
          />
          <feOffset dy="28" />
          <feGaussianBlur stdDeviation="28" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0784314 0 0 0 0 0.176471 0 0 0 0 0.388235 0 0 0 0.08 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_10" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1_10"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0784314 0 0 0 0 0.176471 0 0 0 0 0.388235 0 0 0 0.06 0"
          />
          <feBlend mode="normal" in2="effect1_dropShadow_1_10" result="effect2_dropShadow_1_10" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0784314 0 0 0 0 0.176471 0 0 0 0 0.388235 0 0 0 0.04 0"
          />
          <feBlend mode="normal" in2="effect2_dropShadow_1_10" result="effect3_dropShadow_1_10" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1_10" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
