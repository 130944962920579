import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { COURSE_THEMES_API } from '../api/themes-api'
import { CourseThemeInterface } from '../types/theme-interface'

export default function useThemes({ allowFetch }: { allowFetch: boolean } = { allowFetch: true }) {
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum,
    CourseThemeInterface[]
  >({
    method: RequestMethodsEnum.get,
  })

  const { data: themes, mutate } = useSWR<CourseThemeInterface[]>(() => {
    if (allowFetch) return `${COURSE_THEMES_API}/list`
    return null
  }, fetcher)

  return {
    themes,
    isLoading: isFetching,
    mutate,
  }
}
