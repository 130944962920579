import { CourseThemeInterface } from '../types/theme-interface'

export function getCustomThemeDefaultName(prefix: string, themes?: CourseThemeInterface[]) {
  const number = themes?.reduce((acc, theme) => {
    if (theme.name.includes(prefix)) {
      return acc + 1
    }

    return acc
  }, 1)

  return `${prefix} ${number || 1}`
}
