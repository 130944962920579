export default function ClockIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="CurrentColor">
        <path d="M11.2255 3.72655L10.2412 2.87229C9.32842 2.2254 8.26323 1.82672 7.15 1.71529V0.918083H8.45V0L4.55 0.000436187V0.918083H5.85V1.71529C4.73677 1.82672 3.67158 2.2254 2.75876 2.87229L1.7745 3.72655C0.902624 4.64968 0.32022 5.80797 0.0992144 7.05837C-0.121791 8.30876 0.0282811 9.59651 0.530898 10.7626C1.03351 11.9287 1.86666 12.922 2.92745 13.6199C3.98825 14.3178 5.23022 14.6897 6.5 14.6897C7.76978 14.6897 9.01175 14.3178 10.0725 13.6199C11.1333 12.922 11.9665 11.9287 12.4691 10.7626C12.9717 9.59651 13.1218 8.30876 12.9008 7.05837C12.6798 5.80797 12.0974 4.64968 11.2255 3.72655ZM6.5 13.3828C5.47154 13.3828 4.46617 13.0778 3.61104 12.5064C2.7559 11.935 2.0894 11.1229 1.69583 10.1727C1.30225 9.22256 1.19928 8.17702 1.39992 7.16832C1.60056 6.15961 2.09581 5.23306 2.82305 4.50583C3.55028 3.7786 4.47683 3.28335 5.48553 3.0827C6.49423 2.88206 7.53978 2.98504 8.48995 3.37861C9.44013 3.77219 10.2523 4.43869 10.8236 5.29382C11.395 6.14896 11.7 7.15432 11.7 8.18278C11.6984 9.56143 11.1501 10.8832 10.1752 11.858C9.20039 12.8329 7.87865 13.3812 6.5 13.3828Z" />
        <path d="M6.50156 4.2832V8.1832H2.60156C2.60156 8.95455 2.83029 9.70857 3.25883 10.3499C3.68737 10.9913 4.29646 11.4911 5.0091 11.7863C5.72173 12.0815 6.50589 12.1587 7.26241 12.0083C8.01894 11.8578 8.71385 11.4863 9.25928 10.9409C9.8047 10.3955 10.1761 9.70058 10.3266 8.94405C10.4771 8.18753 10.3999 7.40337 10.1047 6.69074C9.80951 5.9781 9.30963 5.36901 8.66828 4.94047C8.02693 4.51193 7.27291 4.2832 6.50156 4.2832Z" />
      </g>
    </svg>
  )
}
