import {
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { ThemeInterface } from 'themes/types'
import ColorPickerRhf from 'shared/components/color-picker-rhf'
import FormInputRhf from 'shared/components/form-input-rhf'
import { themeColorOptions } from '../consts/theme-color-loco'

type ColorFieldInterface<TValues extends FieldValues, TName extends FieldPath<TValues>> = Omit<
  ControllerProps<TValues, TName>,
  'render'
> & {
  setValue: UseFormSetValue<TValues>
  afterChange?: (value: PathValue<TValues, TName>) => void
  error?: string
}

export default function ThemeEditorColorField<
  TValues extends FieldValues,
  TName extends FieldPath<TValues>,
>({ name, control, setValue, afterChange, error }: ColorFieldInterface<TValues, TName>) {
  const { t } = useTranslation()

  const colorName = themeColorOptions[name as keyof ThemeInterface]

  return (
    <div className="grid grid-cols-[2.5rem_1fr] grid-rows-[2rem_1fr] gap-x-2">
      <span className="col-span-2 self-end">{t(colorName)}</span>
      <ColorPickerRhf
        className="row-start-2 h-8 w-8"
        control={control}
        name={name}
        afterChange={afterChange}
        setValue={setValue}
      />
      <FormInputRhf
        inputClassName="border-none mt-0 pl-1"
        control={control}
        name={name}
        setValue={setValue}
        error={error}
      />
    </div>
  )
}
