import {
  Popover as PopoverHeadless,
  PopoverButton as PopoverHeadlessButton,
  PopoverPanel as PopoverHeadlessPanel,
  Transition,
} from '@headlessui/react'
import React, { ReactNode } from 'react'

type Align = 'start' | 'end'
type Placement = 'top' | 'right' | 'bottom' | 'left'

interface PopoverProps {
  children: (open: boolean) => ReactNode
  label: string | JSX.Element
  position?: `${Placement}` | `${Placement} ${Align}`
  className?: string
  popoverClassName?: string
}

const Popover = ({ children, label, position, className, popoverClassName }: PopoverProps) => (
  <PopoverHeadless className={'flex items-center'}>
    {({ open }) => (
      <>
        <PopoverHeadlessButton
          className={`relative flex cursor-pointer items-center truncate ${
            className ? className : 'h-full w-full max-w-fit'
          } rounded-full outline-none ring-blue ring-offset-1 focus-visible:ring-2`}
        >
          {children(open)}
        </PopoverHeadlessButton>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-75 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <PopoverHeadlessPanel
            portal
            anchor={{ to: position || 'top', gap: 16 }}
            className={`z-50 rounded-lg shadow-2xl ${popoverClassName || ''}`}
          >
            <div className={`relative w-fit whitespace-normal rounded-lg bg-white px-5 py-3`}>
              <div className="w-full break-normal text-center font-avertaPE text-sm font-medium text-gray-300">
                {label}
              </div>
            </div>
          </PopoverHeadlessPanel>
        </Transition>
      </>
    )}
  </PopoverHeadless>
)

export default Popover
