import React from 'react'

function QuizIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5274 3.9381L11.281 0.605099C11.2592 0.597779 11.2353 0.597779 11.2134 0.605099L0.914657 3.88727C0.869307 3.90189 0.838397 3.94375 0.837897 3.99127C0.837397 4.03894 0.867477 4.08131 0.912327 4.09676L3.42849 4.95894V7.94639C3.00603 8.1361 2.7115 8.55956 2.7115 9.05265C2.7115 9.54571 3.00637 9.96951 3.42849 10.1589L2.76696 14.419H4.97214L4.42093 10.1589C4.84339 9.96935 5.13791 9.54572 5.13791 9.05265C5.13791 8.55956 4.84337 8.13611 4.42093 7.94639V5.29882L11.1761 7.61348C11.1876 7.61747 11.1997 7.61946 11.2117 7.61946C11.2237 7.61946 11.2358 7.61763 11.2472 7.61365L21.5285 4.14757C21.5737 4.13229 21.6038 4.08992 21.6034 4.04258C21.6029 3.99473 21.5724 3.95286 21.5273 3.93808L21.5274 3.9381Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2104 8.17106C11.1375 8.17106 11.0658 8.15876 10.9968 8.13517L5.61523 6.29135V10.2296C5.61523 11.3359 8.15747 12.2326 11.2937 12.2326C14.4298 12.2326 16.972 11.3359 16.972 10.2296L16.9719 6.26562L11.4232 8.13621C11.3549 8.15962 11.283 8.1711 11.2104 8.1711L11.2104 8.17106Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default QuizIcon
