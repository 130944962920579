import { useRouter } from 'next/router'
import { CERTIFICATE_OF_COMPLETION_ADDRESS } from '../consts/certificate-of-completion'
import { useCourseData } from './use-course-data'
import { useGetCoursePageUrl } from './use-course-path-url'

export default function useCertificateOfCompletion() {
  const router = useRouter()
  const coursePath = useGetCoursePageUrl()
  const { courseData } = useCourseData()

  const regexp = new RegExp(`\/school\/course\/.*\/${CERTIFICATE_OF_COMPLETION_ADDRESS}`)
  const isOnCertificatePage = regexp.test(router.pathname)

  const isCertificateAvailable = !!(
    courseData?.issueCertificateUponCompletion && courseData.completed
  )

  const certificatePageUrl = `/school/course/${coursePath}/${CERTIFICATE_OF_COMPLETION_ADDRESS}`

  const redirectToCertificate = () => router.replace(certificatePageUrl)

  const getCertificateLink = () =>
    isCertificateAvailable ? `/api/membership/course/${coursePath}/certificate` : ''

  return {
    isOnCertificatePage,
    isCertificateAvailable,
    certificatePageUrl,
    redirectToCertificate,
    getCertificateLink,
  }
}
