import { CourseLectureId } from './course-lecture-interface'
import { CourseQuizId } from './course-quiz-interface'

export enum CourseStepEnum {
  lecture = 'lecture',
  quiz = 'quiz',
}

export type CourseStepType =
  | { type: CourseStepEnum.lecture; courseLectureId: CourseLectureId }
  | { type: CourseStepEnum.quiz; courseQuizId: CourseQuizId }
  | null
