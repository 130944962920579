import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { MEMBERSHIP_COURSE_API } from '../api/membership-api'
import { CourseId } from '../types/course-interface'
import { CourseMenuModuleInterface } from '../types/course-menu-interface'

export interface UseCourseProps {
  courseId: CourseId | undefined
}

export function useCourseMenu({ courseId }: UseCourseProps) {
  const { user } = useUser()

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CourseMenuModuleInterface[]
  >({
    method: RequestMethodsEnum.get,
    afterFetch: (modules: CourseMenuModuleInterface[]) => {
      const modulesWithAvailability = modules.map(module => {
        const isAvailable = module.lectures.some(l => l.available)
        const isCompleted =
          module.lectures.every(lecture => lecture.completed) &&
          (!module.quiz || module.quiz.completed)
        return {
          ...module,
          available: isAvailable,
          isCompleted,
          shouldShowQuiz: module.lectures.length > 0 && isAvailable,
        }
      })

      return modulesWithAvailability
    },
  })

  const { data: courseMenu, mutate } = useSWR<CourseMenuModuleInterface[]>(
    () => user && courseId !== undefined && `${MEMBERSHIP_COURSE_API}/${courseId}/menu`,
    fetcher,
  )

  const areAllModulesUnavailable = courseMenu?.every(module => !module.available)

  return {
    courseMenu,
    mutate,
    areAllModulesUnavailable,
  }
}
