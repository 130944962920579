// NOTE: it doesn't scroll parent (page) unlike the scrollIntoView

export const scrollIntoCenterView = (parentElement: HTMLElement, targetElement: HTMLElement) => {
  const parentElementHalfOfHeight = parentElement.offsetHeight / 2
  const activeElementHalfOfHeight = targetElement.offsetHeight / 2
  const targetElementOffsetTopCompareParents = targetElement.offsetTop - parentElement.offsetTop

  const finalScrollPosition =
    targetElementOffsetTopCompareParents - parentElementHalfOfHeight + activeElementHalfOfHeight

  let currentScrollPosition = parentElement.scrollTop
  let start: number | null = null // start time [ms]
  let duration = 2 * Math.abs(finalScrollPosition - currentScrollPosition) // animation duration [ms]

  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start
    const progress = currentTime - start
    if (currentScrollPosition < finalScrollPosition) {
      currentScrollPosition += ((finalScrollPosition - currentScrollPosition) * progress) / duration
    } else {
      currentScrollPosition -= ((currentScrollPosition - finalScrollPosition) * progress) / duration
    }

    if (progress < duration) {
      parentElement.scrollTop = currentScrollPosition
      window.requestAnimationFrame(step)
    } else {
      parentElement.scrollTop = finalScrollPosition
    }
  })
}
