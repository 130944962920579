import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { ThemeInterface } from 'themes/types'
import { applyTheme } from 'themes/utils'
import { PrimaryButton, SecondaryButton } from 'shared/components/button'
import Drawer from 'shared/components/drawer'
import { DrawerPositionEnum } from 'shared/components/drawer/types'
import FormInputRhf from 'shared/components/form-input-rhf'
import FormSelectRhf from 'shared/components/form-select-rhf'
import { Tooltip } from 'shared/components/tooltip'
import { BadRequest } from 'shared/errors/bad-request'
import SaveIcon from 'shared/icons/save-icon'
import TrashIcon from 'shared/icons/trash-icon'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { themeColorOptions } from '../consts/theme-color-loco'
import useThemeActions from '../hooks/use-theme-actions'
import useThemeEditorToggle from '../hooks/use-theme-editor-toggle'
import useThemes from '../hooks/use-themes'
import { CourseThemeInterface } from '../types/theme-interface'
import { normalizeErrorColorFieldName } from '../utils/normalize-color-set'
import { getCustomThemeDefaultName } from '../utils/theme-number'
import ThemeEditorColorField from './color-field'
import CourseEditorSkeleton from './theme-editor-skeleton'

type ThemeEditorFormInterface = ThemeInterface & { name: string; themeId: number }

const CUSTOM_THEME_STAB_ID = -1

export default function ThemeEditor() {
  const { t } = useTranslation()

  const [initialTheme, setInitialTheme] = useState<CourseThemeInterface>()

  const { isOpenEditor, allowedToEdit, closeEditor } = useThemeEditorToggle()

  const { courseData, mutate: mutateCourseData, isValidating } = useCourseData()

  const { themes, mutate, isLoading } = useThemes({ allowFetch: isOpenEditor })
  const {
    createTheme,
    applyThemeToCourse,
    deleteTheme: deleteThemeForOwner,
    updateTheme,
    unsetTheme,
  } = useThemeActions()

  const themeOptions = [
    { id: CUSTOM_THEME_STAB_ID, caption: t('themes.editor.add_custom_theme') },
    ...(themes?.map(theme => ({ id: theme.id, caption: theme.name })) ?? []),
  ]

  const { handleSubmit, control, setValue, reset, watch, getValues, setError, formState } =
    useForm<ThemeEditorFormInterface>()

  const revalidateCourseData = async () => await mutateCourseData(data => data && { ...data })

  const currentTheme = themes?.find(theme => theme.id === getValues('themeId'))

  const isCustomTheme = !!currentTheme?.user

  const applyThemeToCourseWithUpdate = async (themeId: number) => {
    const updatedCourseData = await applyThemeToCourse(themeId)
    await mutateCourseData(
      courseData =>
        courseData && {
          ...courseData,
          themeId: updatedCourseData.themeId,
        },
    )

    toast.success(t('themes.editor.message.success'))
  }

  const onSubmit: SubmitHandler<ThemeEditorFormInterface> = async data => {
    let themeId = data.themeId

    if (themeId && (themeId === CUSTOM_THEME_STAB_ID || isCustomTheme)) {
      try {
        const isUpdating = themeId !== CUSTOM_THEME_STAB_ID && isCustomTheme
        const newTheme = isUpdating
          ? await updateTheme(themeId, { name: data.name, colorSet: { ...data } })
          : await createTheme({ name: data.name, colorSet: { ...data } })
        await mutate(themes => (themes ? [...themes, newTheme] : [newTheme]))
        themeId = newTheme?.id
      } catch (error) {
        if (error instanceof BadRequest) {
          if (error?.errors?.common) {
            const errorToShow = error.errors.common.join('/n')
            setError('root', { message: errorToShow })
          }

          if (error?.errors?.fields) {
            Object.entries(error.errors.fields).forEach(([key, value]) => {
              if (key === 'colorSet') {
                return Object.entries(value).forEach(([color, colorErrors]) =>
                  setError(normalizeErrorColorFieldName(color) as keyof ThemeEditorFormInterface, {
                    message: (colorErrors as unknown as string[])?.join(''),
                  }),
                )
              }

              setError(key as keyof ThemeEditorFormInterface, { message: value?.join('\n') })
            })
          }
        }

        return
      }
    }

    await applyThemeToCourseWithUpdate(themeId)
  }

  const handleThemeChange = (value: number) => {
    const selectedTheme = themes?.find(theme => theme.id === value)

    if (selectedTheme) {
      reset({ ...selectedTheme.colorSet, name: selectedTheme.name, themeId: value })
      applyTheme(selectedTheme.colorSet)
    }
  }

  const handleColorChange =
    (colorName: keyof ThemeInterface) => (color: string | number | undefined | null) => {
      if (!isCustomTheme) {
        setValue('themeId', -1)
      }

      applyTheme({ ...getValues(), [colorName]: color })
    }

  const handleClose = () => {
    if (courseData?.themeId !== getValues('themeId')) {
      courseData?.colorSet && applyTheme(courseData.colorSet)
    }

    closeEditor()
  }

  const deleteTheme = async () => {
    const themeId = currentTheme?.id

    if (!themeId) return

    await deleteThemeForOwner(themeId)
    await mutate(data => data?.filter(theme => theme.id !== themeId))

    if (themeId === courseData?.themeId) {
      await revalidateCourseData()
    }

    if (courseData) {
      setValue('themeId', courseData.themeId)
      courseData.colorSet && applyTheme(courseData.colorSet)
    }

    if (themeId === initialTheme?.id) {
      setInitialTheme(undefined)
    }
  }

  const handleReset = async () => {
    if (!initialTheme) return

    if (courseData?.themeId !== initialTheme.id) {
      return await applyThemeToCourseWithUpdate(initialTheme.id)
    }

    reset({ ...initialTheme.colorSet, name: initialTheme.name, themeId: initialTheme.id })
    applyTheme(initialTheme.colorSet)
  }

  const canReset = initialTheme?.id !== currentTheme?.id

  useEffect(() => {
    if (!allowedToEdit && isOpenEditor) closeEditor()
  }, [allowedToEdit, isOpenEditor])

  useEffect(() => {
    if (courseData) {
      const current = themes?.find(theme => theme.id === courseData.themeId)
      reset({
        ...courseData.colorSet,
        themeId: courseData.themeId,
        name: current?.name,
      })
    }
  }, [courseData?.themeId, courseData?.colorSet])

  const themeId = watch('themeId')

  useEffect(() => {
    if (themeId === CUSTOM_THEME_STAB_ID) {
      setValue('name', getCustomThemeDefaultName(t('themes.editor.defaults.name'), themes))
    }
  }, [themeId])

  useEffect(() => {
    if (!initialTheme && currentTheme) {
      setInitialTheme(currentTheme)
    }
  }, [currentTheme, initialTheme])

  return (
    <Drawer
      open={isOpenEditor}
      position={DrawerPositionEnum.Right}
      collapsible
      title={<h2 className="text-md">{t('themes.editor.title')}</h2>}
      onClose={handleClose}
    >
      {isValidating || isLoading ? (
        <CourseEditorSkeleton />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
          <div className="flex gap-3">
            <FormSelectRhf
              name="themeId"
              setValue={setValue}
              control={control}
              data={themeOptions}
              className="max-w-full flex-grow"
              afterChange={handleThemeChange}
              withoutCloseIcon
            />
            {isCustomTheme && (
              <Tooltip
                label={
                  <span
                    dangerouslySetInnerHTML={{ __html: t('themes.editor.delete.tooltip') }}
                  ></span>
                }
                mode="hover"
              >
                <SecondaryButton onClick={deleteTheme} type="button">
                  <TrashIcon className="h-5 w-5 fill-darkblue group-hover:fill-white" />
                </SecondaryButton>
              </Tooltip>
            )}
          </div>
          <div className="flex flex-col gap-2 px-2">
            {Object.keys(themeColorOptions).map(color => (
              <ThemeEditorColorField
                key={color}
                name={color as keyof ThemeEditorFormInterface}
                control={control}
                afterChange={handleColorChange(color as keyof ThemeInterface)}
                setValue={setValue}
                error={formState.errors[color as keyof ThemeEditorFormInterface]?.message}
              />
            ))}
          </div>
          {(watch('themeId') === CUSTOM_THEME_STAB_ID || isCustomTheme) && (
            <FormInputRhf
              name="name"
              required
              control={control}
              setValue={setValue}
              placeholder={t('themes.editor.name.placeholder')}
              label={t('global.name')}
              rules={{ required: t('themes.editor.validation.name.required') }}
              error={formState.errors.name?.message}
            />
          )}
          {formState.errors.root && (
            <p className="mt-2 text-sm text-red">{formState.errors.root.message}</p>
          )}
          <PrimaryButton type="submit">
            <SaveIcon className="fill-white" /> {t('themes.editor.cta.save_theme_settings')}
          </PrimaryButton>
          {canReset && (
            <SecondaryButton type="reset" onClick={handleReset}>
              {t('themes.editor.cta.revert_theme_settings')}
            </SecondaryButton>
          )}
        </form>
      )}
    </Drawer>
  )
}
