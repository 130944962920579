import { ThemeInterface } from 'themes/types'

export const themeColorOptions: Record<keyof ThemeInterface, string> = {
  primary: 'themes.editor.colors.primary',
  secondary: 'themes.editor.colors.secondary',
  background: 'themes.editor.colors.background',
  'block-background': 'themes.editor.colors.block_background',
  disabled: 'themes.editor.colors.disabled',
  'header-background': 'themes.editor.colors.header_background',
  'header-text': 'themes.editor.colors.header_text',
}
