import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form'
import FormSelect, { FormSelectProps, OptionsInterface } from './form-select'

type FormSelectRhfProps<
  TOptions extends OptionsInterface,
  TValues extends FieldValues,
  TName extends FieldPath<TValues>,
> = Omit<FormSelectProps<TOptions>, 'value' | 'onChange'> &
  Omit<ControllerProps<TValues, TName>, 'render'> & {
    setValue: UseFormSetValue<TValues>
    afterChange?: (value: PathValue<TValues, TName>) => void
  }

export default function FormSelectRhf<
  TOptions extends OptionsInterface,
  TValues extends FieldValues,
  TName extends FieldPath<TValues>,
>({
  setValue,
  name,
  afterChange,
  control,
  rules,
  ...restProps
}: FormSelectRhfProps<TOptions, TValues, TName>) {
  const handleChange = (value: OptionsInterface['id']) => {
    const typedValue = value as PathValue<TValues, TName>
    setValue(name, typedValue)
    afterChange?.(typedValue)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormSelect {...restProps} value={field.value} onChange={handleChange} />
      )}
    />
  )
}
