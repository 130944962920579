import { ColorPickerBaseProps } from 'react-colorful/dist/types'
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form'
import ColorPicker from './color-picker'

type ColorPickerRhfProps<TValues extends FieldValues, TName extends FieldPath<TValues>> = Omit<
  Partial<ColorPickerBaseProps<string>>,
  'value' | 'onChange' | 'color'
> &
  Omit<ControllerProps<TValues, TName>, 'render'> & {
    setValue: UseFormSetValue<TValues>
    afterChange?: (value: PathValue<TValues, TName>) => void
  }

export default function ColorPickerRhf<
  TValues extends FieldValues,
  TName extends FieldPath<TValues>,
>({
  control,
  name,
  rules,
  setValue,
  afterChange,
  ...restProps
}: ColorPickerRhfProps<TValues, TName>) {
  const handleChange = (value: string) => {
    const typedValue = value as PathValue<TValues, TName>
    setValue(name, typedValue)
    afterChange?.(typedValue)
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <ColorPicker {...restProps} color={field.value} onChange={handleChange} />
      )}
    />
  )
}
