import React, { useLayoutEffect, useRef } from 'react'

export function DangerousElement({ markup }: { markup: string }) {
  const elRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (elRef.current) {
      const range = document.createRange()
      range.selectNode(elRef.current)
      const documentFragment = range.createContextualFragment(markup)

      // Inject the markup, triggering a re-run!
      elRef.current.innerHTML = ''
      elRef.current.append(documentFragment)
    }
  }, [markup])

  return <div ref={elRef} dangerouslySetInnerHTML={{ __html: markup }} />
}
