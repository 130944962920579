import { useMemo } from 'react'
import { useCourseData } from './use-course-data'

export const useSetFonts = () => {
  const { courseData } = useCourseData()

  const fontFamily = courseData?.fontFamily !== 'AvertaPE' && courseData?.fontFamily

  const fontStyle = useMemo(
    () =>
      fontFamily &&
      `
        @font-face {
          font-family: ${fontFamily};
          font-weight: normal;
          src: url(${process.env.NEXT_PUBLIC_CLOUDFRONT_SHARED}/fonts/google-fonts/${fontFamily
            .toLowerCase()
            .replace(/\s/g, '')}/regular.woff2) format('woff2');
        }
      `,

    [fontFamily],
  )

  return {
    fontStyle,
    fontFamily,
  }
}
