import useUser, {
  isAuthorizedUser,
  isWorkspaceOwner,
  isWsAdmin,
  isWsAssistant,
} from 'shared/hooks/use-user'
import { useCourseData } from './use-course-data'

export const useIsUserAdminOrOwnerOfCourse = () => {
  const { user } = useUser()
  const { courseData } = useCourseData()

  return (
    user &&
    isAuthorizedUser(user) &&
    ((courseData?.owner.id === user.id && isWorkspaceOwner(user)) ||
      (courseData?.owner.id == user.workspaceOwner && (isWsAdmin(user) || isWsAssistant(user))))
  )
}
