import { themeColorOptions } from '../consts/theme-color-loco'
import { ThemeCreateColorKeysType, ThemeCreateColorSet } from '../types/theme-interface'

function normalizeName(name: string): string {
  const normalizedName = name
    .split('-')
    .map((namePart, i) => (i === 0 ? namePart : namePart[0].toUpperCase() + namePart.slice(1)))
    .join('')

  return normalizedName
}

export function normalizeColorSet<T extends Record<string, any>>(colorSet: T) {
  const normalizedColorSet = Object.keys(themeColorOptions).reduce((acc, next) => {
    const normalizedName = normalizeName(next)
    acc[normalizedName as ThemeCreateColorKeysType] = colorSet[next]
    return acc
  }, {} as ThemeCreateColorSet)

  return normalizedColorSet
}

export function normalizeErrorColorFieldName(fieldName: string) {
  return fieldName.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    (substr, isMatch) => (isMatch ? '-' : '') + substr.toLowerCase(),
  )
}
