import { ComponentProps } from 'react'

export default function RoundedLockIcon(props: ComponentProps<'svg'>) {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 100 100"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      fill="CurrentColor"
      {...props}
    >
      <path d="m75.0672379 39.3663406v-11.7995338c0-13.8166094-11.2331315-25.0668068-25.0668107-25.0668068-13.8166084 0-25.0668068 11.2501974-25.0668068 25.0668068v11.7995338c-5.783392.4833336-10.3333912 5.366642-10.3333912 11.2661972v14.067276c.0000001 18.0832978 14.6998158 32.8001862 32.7831174 32.8001862h5.2333107c18.0833015 0 32.7831154-14.7168808 32.7831154-32.8001862v-14.067276c0-5.8999825-4.5500031-10.7829971-10.3333893-11.2661972zm-39.2834244-11.7995338c.7791748-18.8374386 27.6545563-18.8331718 28.4332275 0v11.7504654h-28.4332199zm10.2500877 42.1506386c-5.8500557-3.8166656-2.9958572-13.1456757 3.9667435-13.0838051 6.9624939-.0583344 9.8208542 9.2625618 3.9667397 13.0838051-.4500046.3000031-.7333374.8333359-.7333374 1.3833694v5.8500519c-.1083298 4.2583771-6.3541679 4.2666931-6.4667015 0v-5.8500519c0-.5499954-.2833328-1.0833206-.7333374-1.3833694z" />
    </svg>
  )
}
