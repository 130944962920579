import React from 'react'

function CircleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.032 7.58875C14.032 11.0105 11.2581 13.7844 7.83631 13.7844C4.41453 13.7844 1.64062 11.0105 1.64062 7.58875C1.64062 4.16697 4.41453 1.39307 7.83631 1.39307C11.2581 1.39307 14.032 4.16697 14.032 7.58875Z"
        stroke="#CBD5E1"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default CircleIcon
