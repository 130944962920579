export default function CourseEditorSkeleton() {
  return (
    <div className="flex w-full animate-pulse flex-col gap-5 overflow-hidden bg-white">
      <div className="h-8 w-64 rounded-sm bg-gray" />
      {Array.from({ length: 7 }).map((_, i) => (
        <div key={i} className="grid grid-cols-[2.5rem_1fr] grid-rows-[2rem_1fr] gap-2">
          <div className="col-span-2 h-8 w-48 rounded-sm bg-gray" />
          <div className="h-8 w-8 rounded-full bg-gray" />
          <div className="h-8 w-48 rounded-sm bg-gray" />
        </div>
      ))}
      <div className="h-10 w-64 self-center rounded-sm bg-gray" />
    </div>
  )
}
