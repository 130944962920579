import { useRouter } from 'next/router'
import useSWR from 'swr'
import { baseTheme } from 'themes/constants'
import { applyTheme } from 'themes/utils'
import { NotFound } from 'shared/errors/not-found'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { getSchoolUrl } from 'modules/school/utils/get-school-url'
import { MEMBERSHIP_COURSE_API } from '../api/membership-api'
import { CourseAccessTypeEnum, CourseDataInterface } from '../types/course-interface'
import { useGetCoursePageUrl } from './use-course-path-url'

export function useCourseData() {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, CourseDataInterface>(
    {
      method: RequestMethodsEnum.get,
      afterFetch: courseData => ({
        ...courseData,
        dominantAccessType: courseData.dominantAccessType ?? CourseAccessTypeEnum.NoAccess,
      }),
    },
  )

  const router = useRouter()

  const coursePath = useGetCoursePageUrl()
  const {
    data: courseData,
    mutate,
    isValidating,
  } = useSWR<CourseDataInterface>(
    () => user && coursePath !== undefined && `${MEMBERSHIP_COURSE_API}/${coursePath}`,
    fetcher,
    {
      onError: error => {
        applyTheme(baseTheme)
        if (error instanceof NotFound) {
          router.push(getSchoolUrl())
        }
      },
    },
  )

  return { courseData, mutate, isValidating }
}
