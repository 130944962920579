import { ComponentProps } from 'react'
import PrimaryButton from 'shared/components/primary-button'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'

export const PrimaryButtonWithCustomTheme = <
  P extends ComponentProps<typeof PrimaryButton> = ComponentProps<typeof PrimaryButton>,
>({
  className,
  ...restProps
}: P) => (
  <PrimaryButton
    className={`!bg-THEME-secondary !text-THEME-block-background hover:!bg-THEME-hover-secondary hover:!text-THEME-block-background disabled:!border-THEME-disabled disabled:!bg-THEME-disabled ${className || ''} ${addPublicCustomClassNamePrefix(['course__button', 'course__button--primary'])}`}
    {...restProps}
  />
)
