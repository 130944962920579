import { ComponentProps } from 'react'
import SecondaryButton from 'shared/components/secondary-button'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'

export const SecondaryButtonWithCustomTheme = <
  P extends ComponentProps<typeof SecondaryButton> = ComponentProps<typeof SecondaryButton>,
>({
  className,
  ...restProps
}: P) => (
  <SecondaryButton
    className={`!border-THEME-primary !bg-THEME-block-background !text-THEME-primary hover:!border-THEME-block-background hover:!bg-THEME-primary hover:!text-THEME-block-background disabled:!border-THEME-disabled disabled:!text-THEME-disabled ${className || ''} ${addPublicCustomClassNamePrefix(['course__button', 'course__button--secondary'])}`}
    {...restProps}
  />
)
