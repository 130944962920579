import useWindowSize from './use-window-size'

export default function useMediaQuery() {
  const { width } = useWindowSize()

  return {
    width,
    isSM: width >= 640,
    isMD: width >= 784,
    isLG: width >= 1024,
    isXL: width >= 1280,
    is2XL: width >= 1536,
  }
}
