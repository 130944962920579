import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import { getLoginUrl } from 'shared/utils/url-utils'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { CourseInfoScreenLayout } from './course-info-screen-layout'

export const CourseLoginScreen = () => {
  const {} = useTranslation()
  const { user } = useUser()

  return (
    <CourseInfoScreenLayout
      className={addPublicCustomClassNamePrefix(`course__info-screen--login`)}
      text={
        <Trans
          i18nKey="course.login_message"
          components={{
            a: (
              <LinkWithoutPrefetch
                className="primary-link"
                href={(user?.dashboardLocale && getLoginUrl(user.dashboardLocale)) || ''}
              />
            ),
          }}
        />
      }
    />
  )
}
