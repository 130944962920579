import React, { ReactNode } from 'react'
import useMediaQuery from 'shared/hooks/use-media-query'
import LockIcon from 'shared/icons/lock-icon'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'

interface CourseLoginOrPurchaseScreenProps {
  text: ReactNode
  icon?: ReactNode
  buttons?: ReactNode
  className?: string
}

export const CourseInfoScreenLayout = ({
  className,
  icon = (
    <div className="rounded-full bg-THEME-disabled p-5">
      <LockIcon className="h-10 w-10 fill-THEME-background" />
    </div>
  ),
  text,
  buttons,
}: React.PropsWithChildren<CourseLoginOrPurchaseScreenProps>) => {
  const { isLG } = useMediaQuery()
  return (
    <div
      className={`basis-full ${className || ''} ${addPublicCustomClassNamePrefix('course__info-screen')}`}
    >
      <div
        className={`mt-10 flex w-full flex-col items-center justify-center lg:mt-0 ${
          className || ''
        }`}
        style={{ transform: isLG ? 'translateY(min(100vh, 50vh - 100%))' : '' }}
      >
        {icon}
        <p className="mt-5 text-center text-base font-semibold text-THEME-primary lg:text-xl">
          {text}
        </p>
        <div className="mt-7 flex w-full flex-wrap justify-center gap-3">{buttons}</div>
      </div>
    </div>
  )
}
