import { hexToRGBA } from 'shared/utils/color-convert/hex-to-rgba'
import { additionalColors } from './constants'
import { MappedThemeInterface, ThemeInterface } from './types'

const mapTheme = (variables: ThemeInterface) => {
  return Object.entries(variables).reduce(
    (res, [key, value]) => ({ ...res, [`--THEME-${key}`]: value || '' }),
    {} as MappedThemeInterface,
  )
}

const addAdditionalColors = (baseColors: ThemeInterface) => {
  const baseColorKeys = Object.keys(baseColors)
  return {
    ...baseColors,
    ...Object.entries(additionalColors).reduce(
      (res, [additionalColorName, additionalColorParams]) =>
        baseColors[additionalColorParams.baseColor] &&
        !baseColorKeys.includes(additionalColorName) &&
        additionalColorParams.opacity
          ? {
              ...res,
              [additionalColorName]: hexToRGBA(
                baseColors[additionalColorParams.baseColor] as string,
                additionalColorParams.opacity,
              ),
            }
          : res,
      {} as ThemeInterface,
    ),
  }
}

export const applyTheme = (theme: ThemeInterface) => {
  const themeObject = mapTheme(addAdditionalColors(theme))
  if (themeObject) {
    const root = document.documentElement

    Object.keys(themeObject).forEach(property => {
      root.style.setProperty(property, themeObject[property])
    })
  }
}
