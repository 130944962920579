import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import {
  FetcherType,
  RequestMethodsEnum,
} from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { CourseDataInterface } from 'modules/course/types/course-interface'
import { APPLY_THEME_API, COURSE_THEMES_API, UNSET_THEME_API } from '../api/themes-api'
import { CourseThemeInterface, ThemeCreateInterface } from '../types/theme-interface'

export default function useThemeActions() {
  const { courseData } = useCourseData()

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum>({
    method: RequestMethodsEnum.post,
  })

  const { fetcher: deleteFetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum>({
    method: RequestMethodsEnum.delete,
  })

  const { fetcher: updateFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum,
    CourseThemeInterface
  >({ method: RequestMethodsEnum.put })

  const getPostFetcher = <T>() => fetcher as unknown as FetcherType<RequestMethodsEnum, T>

  const createTheme = (theme: ThemeCreateInterface): Promise<CourseThemeInterface> =>
    getPostFetcher<CourseThemeInterface>()(`${COURSE_THEMES_API}/create`, theme)

  const applyTheme = (themeId: number) => {
    const courseId = courseData?.id ?? 0
    const fetcher = getPostFetcher<CourseDataInterface>()
    return fetcher(APPLY_THEME_API(courseId, themeId))
  }

  const deleteTheme = (themeId: number) => deleteFetcher(`${COURSE_THEMES_API}/${themeId}/delete`)

  const updateTheme = (themeId: number, theme: ThemeCreateInterface) =>
    updateFetcher(`${COURSE_THEMES_API}/${themeId}/update`, theme)

  const unsetTheme = () =>
    getPostFetcher<ThemeCreateInterface>()(UNSET_THEME_API(courseData?.id ?? 0))

  return { createTheme, applyThemeToCourse: applyTheme, deleteTheme, updateTheme, unsetTheme }
}
