import React from 'react'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'

export const CourseContentSkeleton = ({ className }: { className?: string }) => (
  <div
    className={`h-[80vh] w-full animate-pulse rounded-md bg-THEME-disabled ${
      className || ''
    } ${addPublicCustomClassNamePrefix('course__content-skeleton')}`}
  />
)
