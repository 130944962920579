import { AdditionalColorsType, ThemeInterface } from './types'

// NOTE: to set color for skeletons
export const initialTheme: ThemeInterface = {
  disabled: '#CBD5E1',
}

export const baseTheme: ThemeInterface = {
  background: '#F1F4F8',
  'block-background': '#FFFFFF',
  'header-background': '#142C63',
  'header-text': '#FFFFFF',
  primary: '#142C63',
  secondary: '#00A0FF',
  disabled: '#CBD5E1',
}

export const additionalColors: AdditionalColorsType = {
  'hover-secondary': { baseColor: 'secondary', opacity: 0.8 },
  'hover-primary': { baseColor: 'primary', opacity: 0.8 },
  'hover-header-text': { baseColor: 'header-text', opacity: 0.8 },
}
