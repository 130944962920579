import { CourseLectureId } from '../types/course-lecture-interface'
import { CourseQuizId } from '../types/course-quiz-interface'
import { CourseStepEnum } from '../types/course-step-type'

type GetCoursePageUrlArgs = { coursePath: string } & (
  | {
      id: CourseLectureId
      type: CourseStepEnum.lecture
    }
  | {
      id: CourseQuizId
      type: CourseStepEnum.quiz
    }
  | {
      id?: never
      type?: never
    }
)

export const getCoursePageQuery = ({ coursePath, id, type }: GetCoursePageUrlArgs) =>
  `/school/course/${coursePath}/${type}/${id}`
